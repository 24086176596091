<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <vuetable ref="vuetable"
      @vuetable:load-error="handleLoadError"
      :api-url="apiUrl"
      :http-options="HttpOptions"
      :fields="fields"
      pagination-path=""
      :muti-sort="true"
      :sort-order="sortOrder"
      :append-params="moreParams"
    >
      <template slot="partner-slot" slot-scope="prop">
        <span>{{ prop.rowData.partner_name }}</span>
      </template>
      <template slot="current_balance-slot" slot-scope="prop">
        <span>{{ prop.rowData.current_balance }}</span>
      </template>
      <template slot="total_topup-slot" slot-scope="prop">
        <span>{{ prop.rowData.total_topup }}</span>
      </template>
      <template slot="total_deduct-slot" slot-scope="prop">
        <span>{{ prop.rowData.total_deduct }}</span>
      </template>
    </vuetable>
    <b-row class="mt-4 mb-3">
      <b-col sm>
        <div>
          <button class="btn btn-success m-2" type="button" @click="handleUpdate">Update Balance</button>
          <a
            class="btn btn-info m-2"
            href="voucher-manage-balance/history-transaction"
            rel="noreferrer noopener"
            v-if="$can('partner_voucher_manage_balance_history')"
          >
            History Transaction
          </a>
        </div>
      </b-col>
    </b-row>
    <!-- Update Balance -->
    <b-card class="wrapper-update" v-show="isUpdate">
      <b-card-header>
        <p>Update Balance</p>
      </b-card-header>
      <b-card-body>
        <b-form v-on:submit.prevent="handleModal" novalidate action="https://vuejs.org/">
          <b-row>
            <b-col sm="6">
              <div>
                <b-form-group
                  class="mb-3"
                  label="Partner"
                  label-for="partner"
                >
                  <b-form-select
                    id="partner"
                    aria-describedby="typeFeedback"
                    v-model.trim="form.partner"
                    :plain="true"
                    :options="optionsPartner"
                  ></b-form-select>
                </b-form-group>
              </div>
            </b-col>
            <b-col sm="6">
              <div>
                <b-form-group
                  class="mb-3"
                  label="Input Balance"
                  label-for="inputBalance"
                >
                  <b-input-group>
                    <b-form-input
                      id="inputBalance"
                      type="number"
                      v-model.trim="form.balance"
                      placeholder="Input Balance"
                      min="0"
                    >
                    </b-form-input>
                  </b-input-group>
                </b-form-group>
              </div>
            </b-col>
            <b-col sm="6">
              <div>
                <b-form-group
                  class="mb-3"
                  label="Transaction Type"
                  label-for="sp"
                >
                  <b-form-select
                    id="sp"
                    v-model.trim="form.transaction"
                    :options="optionsTransaction"
                  ></b-form-select>
                </b-form-group>
              </div>
            </b-col>
            <b-col sm="6">
              <div>
                <b-form-group
                  class="mb-3"
                  label="Insert OTP Code"
                  label-for="otp"
                >
                  <b-input-group>
                    <b-form-input
                      id="otp"
                      type="number"
                      v-model.trim="form.otp"
                      placeholder="OTP Code"
                      min="0"
                    >
                    </b-form-input>
                    <b-input-group-append
                    >
                      <b-button
                        variant="secondary"
                        type="button"
                        @click="fetchOtp"
                      >
                        Get OTP Code
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>
            </b-col>
            <b-col sm="6">
              <div>
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="!this.form.partner || !this.form.balance || this.form.balance === 0 || !this.form.transaction || !this.form.otp || this.form.otp.length < 6"
                  v-if="$can('partner_voucher_manage_balance_update')"
                >
                  Save
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
    </b-card>
    <b-modal v-model="isModal" size="md" hide-footer hide-header centered>
      <b-row class="py-4">
        <b-col class="mb-4" sm="12">
          <h5 class="text-center update-copytext">
            {{ `Are you sure want to ${handleGetSelectName('trx', this.form.transaction)} ${handleParseCurency(this.form.balance)} for partner ${handleGetSelectName('partner', this.form.partner)} ?` }}
          </h5>
        </b-col>
        <b-col sm="12">
          <div class="action-wrapper">
            <b-button
             variant="outline-secondary"
             @click="handleModal"
            >No</b-button>
            <b-button
             variant="primary"
             @click="onSubmit"
             v-if="isModal && !isLoading"
            >Yes</b-button>
            <b-button variant="primary" v-else-if="isModal && isLoading" disabled>
              <b-spinner small></b-spinner>
              <span class="sr-only">Loading...</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
  import axios from 'axios'
  import Vue from 'vue'
  import Vuetable from 'vuetable-2/src/components/Vuetable'

  Vue.use(Vuetable)

  export default {
    components: {
      Vuetable
    },
    prop: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    data() {
      return {
        isUpdate: false,
        isLoading: false,
        isModal: false,
        data: [],
        optionsPartner: [
          { value: '', text: 'Choose Partner' }
        ],
        optionsTransaction: [
          { value: '', text: 'Choose Transaction Type' },
          { value: '1', text: 'Top Up Balance' },
          { value: '5', text: 'Deduct Balance' }
        ],
        errors: {
          code: '',
          message: '',
          status: '',
          form: {
            partner: [],
            balance: [],
            transaction: [],
            otp: []
          },
        },
        form: {
          partner: '',
          balance: '',
          transaction: '',
          otp: ''
        },
        apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `voucher/partner/manage-balance/partner-list`,
        HttpOptions: {
          headers: {
            'Accept' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
          }
        },
        sortOrder: [
          {
            field: 'created_at',
            sortField: 'created_at',
            direction: 'desc'
          }
        ],
        moreParams: {},
        fields: [
          {
            name: 'partner-slot',
            sortField: 'partner',
            title: 'Partner'
          },
          {
            name: 'current_balance-slot',
            sortField: 'current_balance',
            title: 'Current Balance'
          },
          {
            name: 'total_topup-slot',
            sortField: 'total_topup',
            title: 'Total Topup'
          },
          {
            name: 'total_deduct-slot',
            sortField: 'total_deduct',
            title: 'Total Deduction'
          },
        ]
      }
    },
    created() {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
      this.fetchPartners()
    },
    methods: {
      fetchPartners() {
        this.$http.get(`partner-list`)
          .then((result) => {
            this.isLoading = false
            const newPartner = result.data.map((item) => { return { value: item.id, text: item.name } })
            this.isLoading = false
            this.optionsPartner = [
              ...this.optionsPartner,
              ...newPartner
            ]
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
            }
          })
      },
      fetchOtp() {
        this.$http.get(`voucher/partner/manage-balance/otp`)
          .then((result) => {
            this.isLoading = false
            this.$toasted.success(result?.data?.meta?.message)
          }).catch((error) => {
            this.isLoading = false
            this.$toasted.error(error?.data?.meta?.message)
          })
      },
      handleLoadError(error) {
        this.errors.code = error.response.data.meta.code;
        this.errors.message = error.response.data.meta.message;
        this.errors.status = error.response.data.meta.code;
        if (this.errors.code == 401) {
          if (localStorage.getItem('access_token') != null) {
            localStorage.removeItem('access_token');
            this.$swal.fire(
              'Your session expired!',
              'Your session has expired. Please login again to access this page!',
              'error'
            ).then(() =>{
              this.$router.push("/login")
            })
          }
        }else if(this.errors.code == 403) {
          this.$router.push("/403")
        }else if(this.errors.code == 500) {
          this.$router.push("/500")
        }
      },
      handleUpdate() {
        this.isUpdate = !this.isUpdate

        if (this.isUpdate) {
          this.filterBalance = ''
          this.filterPartner = ''
          this.filterTransction = ''
          this.filterOtp = ''
        }
      },
      onSubmit() {
        if (this.isFormValid()) {
          this.isLoading  = true
          const formData =  new FormData();
          formData.append("partner_id", this.form.partner);
          formData.append("amount", this.form.balance.toString());
          formData.append("type", this.form.transaction);
          formData.append("otp", this.form.otp);

          this.$http.post(`voucher/partner/manage-balance/update`, formData)
            .then((response) => {
              this.isLoading  = false

              if (response.status == 200) {
                this.handleModal()
                this.$toasted.success(response.data.meta.message)
                this.fetchPartners()
                this.isUpdate = false;
                this.form = {
                  partner: '',
                  balance: '',
                  transaction: '',
                  otp: ''
                };
              }
            }).catch((error) => {
              if (error.response) {
                this.handleModal()
                this.isLoading = false
                this.errors.code = error.response.status;
                this.errors.status = error.response.data.meta.code;
                this.errors.headers = error.response.headers;
                if(this.errors.status == 422) {
                  this.$toasted.error('Please fill out the form that must be required')
                  this.errors.message = error.response.data.meta.message;
                  this.errors.form.partner = this.errors.message.partner;
                  this.errors.form.balance = this.errors.message.balance;
                  this.errors.form.transaction = this.errors.message.transaction;
                  this.errors.form.otp = this.errors.message.otp;
                }else if(this.errors.status == 400){
                  this.errors.message = error.response.data.meta.message;
                  this.$swal.fire(
                    'Failed!',
                    this.errors.message,
                    'error'
                  )
                }
              }
            })
        }
      },
      isFieldValid(fieldName) {
        return this.form[fieldName] !== '';
      },
      isBalanceValid() {
        return this.form.balance !== null && !isNaN(this.form.balance) && this.form.balance >= 0;
      },
      isOTPValid() {
        return /^\d{6}$/.test(this.form.otp);
      },
      isFormValid() {
        return (
          this.isFieldValid('partner') &&
          this.isBalanceValid() &&
          this.isFieldValid('transaction') &&
          this.isOTPValid()
        );
      },
      handleGetSelectName(type, value) {
        console.log('val', type, value)
        if (type === 'partner') {
          return this.optionsPartner.filter((itm) => itm.value === value)[0]?.text
        } else {
          return this.optionsTransaction.filter((itm) => itm.value === value)[0]?.text
        }
      },
      handleParseCurency(value) {
        return new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }).format(value);
      },
      handleModal() {
        this.isModal = !this.isModal
      }
    }
  }
</script>
<style>
.is-invalid {
  border: 1px solid red;
}

.error-message {
  color: red;
  margin-top: 5px;
  font-size: 10px;
}

.wrapper-update {
  padding: 0;
}
.wrapper-update > .card-body {
  padding: 0;
}

.badge--code {
  font-size: 10pt !important;
}
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

.update-copytext {
  width: 400px;
  margin: 0 auto;
}

.action-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.action-wrapper > button {
  width: 80px;
}
.action-wrapper > button.btn-outline-secondary {
  color: #606060;
  margin-right: 28px;
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
